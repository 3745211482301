<template>
  <div class="with_draw_container">
    <div class="task_hall_title">
      <div class="logo">
        <img src="@/assets/images/task-manager.png" alt="提现/充值">
      </div>
      <div style="margin-left: 10px;">提现/充值</div>
    </div>

    <!-- 提现与充值表格 -->
    <el-tabs
      v-model="activeName"
      class="task_hall_table">
      <el-tab-pane
        label="充值记录"
        name="first">
        <top-up-table></top-up-table>
      </el-tab-pane>
        <el-tab-pane
        label="提现记录"
        name="second">
        <with-draw-table></with-draw-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TopUpTable from './components/top-up-table.vue'
import WithDrawTable from './components/with-draw-table.vue'

export default {
  data () {
    return {
      activeName: 'first'
    }
  },
  components: {
    TopUpTable,
    WithDrawTable
  }
}
</script>

<style lang="scss" scoped>
.with_draw_container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .task_hall_title {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
