<template>
  <div>
    <el-table
      class="table_border"
      v-loading="isLoading"
      :cell-style="cellStyle"
      :data="tableList">
      <el-table-column
        label="ID"
        :index="indexMethod"
        type="index">
      </el-table-column>
      <el-table-column
        label="申请人"
        prop="username">
      </el-table-column>
      <el-table-column
        label="金额"
        prop="amount">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span :style="{
            [WITHDRAW_STATUS.PENDING]: 'color: #E6A23C',
            [WITHDRAW_STATUS.PROCESSED]: 'color: #67C23A',
            [WITHDRAW_STATUS.CANCELED]: ''
          }[scope.row.status]">
          {{
            {
              [WITHDRAW_STATUS.PENDING]: '待处理',
              [WITHDRAW_STATUS.PROCESSED]: '已处理',
              [WITHDRAW_STATUS.CANCELED]: '已取消'
            }[scope.row.status]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="打款凭证">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="onPreview(scope.row.attachments.url)">
            查看图片
          </el-button>
          <el-image-viewer
            v-if="showViewer"
            :on-close="closeViewer"
            :url-list="[url]">
          </el-image-viewer>
        </template>
      </el-table-column>
      <el-table-column
        label="操作人"
        prop="operator"
        width="160">
      </el-table-column>
      <el-table-column
        label="操作时间"
        width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.status !== WITHDRAW_STATUS.PENDING">
            {{scope.row.updatedAt}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="150">
        <template
          slot-scope="scope"
          v-if="scope.row.status === WITHDRAW_STATUS.PENDING">
          <el-button
            type="text"
            style="color: #3473E6;"
            @click="confirmTopUp(scope.row.id)">
            确认
          </el-button>
          <el-button
            type="text"
            style="color: #F56C6C;"
            @click="cancelTopUp(scope.row.id)">
            取消
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <pagination
      @paginationChange="paginationChange"
      :pageInfo="pageInfo">
    </pagination>
  </div>
</template>

<script>
import api from '@/api/index'
import Pagination from '@/components/pagination/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    Pagination,
    ElImageViewer
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      isLoading: false,
      tableList: [],
      showViewer: false,
      url: ''
    }
  },
  computed: {
    WITHDRAW_STATUS () {
      return globalVar.WITHDRAW_STATUS
    }
  },
  created () {
    this.getList()
  },
  methods: {
    cellStyle () {
      return 'height: 48px; padding: 0px;'
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    },
    // 确认充值
    confirmTopUp (id) {
      api.adminRechargeConfirm(id).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error('操作失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      })
    },
    // 取消充值
    cancelTopUp (id) {
      api.adminRechargeCancel(id).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error('操作失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      })
    },
    paginationChange (newNum) {
      this.pageInfo.pageNum = newNum
      this.getList()
    },
    // 获取充值记录列表
    getList () {
      this.isLoading = true
      api.adminRechargeList({
        ...this.pageInfo
      }).then(res => {
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.pageInfo.total = total
          this.tableList = list
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('获取列表失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求错误')
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 点击按钮预览图片
    onPreview (url) {
      this.url = url
      this.showViewer = true
    },
    // 关闭图片预览
    closeViewer () {
      this.showViewer = false
    }
  }
}
</script>

<style lang="scss" scoped>
.table_border {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
